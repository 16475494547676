<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searParkRecordList">
        <el-form
          :inline="true"
          ref="searchWrapper"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber">
                <el-autocomplete
                  size="12"
                  valueKey="plateNumber"
                  class="inline-input"
                  v-model="plateNumber"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.phone_number')" prop="mobile">
                <el-input
                  :maxlength="11"
                  v-model.trim="formInline.mobile"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.channel')">
                <el-select v-model="formInline.channel">
                  <el-option label="全部" value="" key=""></el-option>
                  <el-option
                    v-for="item in phoneTypeList"
                    :label="item.desc"
                    :key="item.code"
                    :value="item.code"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.remarks')">
                <el-select v-model="formInline.remark">
                  <el-option label="全部" value="" key=""></el-option>
                  <el-option
                    v-for="item in remarkList"
                    :label="item.desc"
                    :value="item.code"
                    :key="item.code"
                  >
                  </el-option>
                </el-select>
                <!-- <el-select v-model.trim="operationId" filterable clearable @change="change">
                  <el-option label="全部" :value="''" :key="''"></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in operationList"
                  >
                  </el-option>
                </el-select> -->
              </el-form-item>
              <el-form-item :label="$t('searchModule.Operation_date')">
                <el-date-picker
                  v-model="rangeDate"
                  @change="changeTime"
                  type="datetimerange"
                  :picker-options="pickerOptions"
                  :clearable="false"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['00:00:00', '23:59:59']"
                  range-separator="-"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                ></el-date-picker>
                <i class="el-icon-date el-icon-date-custom"></i>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Operator')" prop="operatorName">
                <el-input
                  :maxlength="30"
                  v-model.trim="formInline.operatorName"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
              <el-button type="info" icon="el-icon-delete" @click="resetForm()">清空</el-button>
            </div>
          </div>
          <!-- <el-button type="primary" @click="add">新增</el-button> -->
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <!--<el-table-column type="index" :label="$t('list.index')" width="70"></el-table-column>-->
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
          <el-table-column label="操作" align="center" width="80">
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="a">查看</el-dropdown-item>
                  <el-dropdown-item command="b">移除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            ></el-pagination>
          </div>
        </div>
      </div>
      <!-- 新增无效电话弹框 -->
      <el-dialog
        title="新增无效电话"
        width="500px"
        :visible.sync="invalidVisible"
        :close-on-click-modal="false"
        @close="closeDialogFn"
      >
        <el-form :model="addformInline" label-width="100px" ref="addForm" :rules="rule">
          <el-form-item :label="$t('searchModule.plate_number')">
            <el-input
              v-model="addformInline.plateNumber"
              placeholder="车牌号格式：蓝：粤A12345"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.phone_number')" prop="mobile">
            <el-input
              :maxlength="11"
              v-model="addformInline.mobile"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="上传录音文件">
            <el-upload
              ref="upLoad"
              class="avatar-uploader"
              accept=".wav"
              action="/acb/2.0/carMobileBlack/uploadAudio"
              :headers="uploadHeader"
              :show-file-list="false"
              :auto-upload="false"
              :on-change="audioChange"
              :file-list="fileList"
              :http-request="uploadFile"
            >
              <div v-if="fileList.length > 0" class="avatar">
                {{ fileList[0].name }}
              </div>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            请上传wav格式录音文件
          </el-form-item>
          <el-form-item :label="$t('searchModule.remarks')" prop="text">
            <el-select v-model="addformInline.text">
              <el-option label="请选择" value=""></el-option>
              <el-option label="投诉" value="1"></el-option>
              <el-option label="多次拨打无人接听" value="2"></el-option>
              <el-option label="空号" value="3"></el-option>
              <el-option label="其他" value="4"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="invalidVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveAddBlackFn">保 存</el-button>
        </span>
      </el-dialog>
      <!-- 查看无效电话弹框 -->
      <el-dialog
        title="详情"
        :visible.sync="dialogVisible"
        width="500px"
        :close-on-click-modal="false"
        @close="closeDialogFn"
      >
        <el-form label-position="right" :model="addformInline" label-width="110px">
          <el-form-item label="车牌号：">
            {{ detailformInline.plateNumber }}
          </el-form-item>
          <el-form-item label="手机号：">
            {{ detailformInline.mobile }}
          </el-form-item>
          <el-form-item label="上传录音文件：">
            <audio :src="audioUrl" controls ref="audio" controlsList="nodownload"></audio>
          </el-form-item>
          <el-form-item label="渠道:">
            {{ detailformInline.channelDesc }}
          </el-form-item>
          <el-form-item label="无效电话备注：">
            <div>{{ detailformInline.remarkDesc }}</div>
          </el-form-item>
          <el-form-item label="操作人：">
            {{ detailformInline.operatorName }}
          </el-form-item>
          <el-form-item label="操作日期：">
            {{ detailformInline.updatedTime }}
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { setIndex, dateFormat, exportExcelNew } from "@/common/js/public.js";
const now = new Date();
const start = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
start.setHours(0);
start.setMinutes(0);
start.setSeconds(0);
now.setHours(23);
now.setMinutes(59);
now.setSeconds(59);
export default {
  name: "staggeredOrder",
  data() {
    let checkPhone = (rule, value, callback) => {
      if (!/^\d{11}$/.test(value)) {
        callback(new Error("手机号格式不正确"));
      } else {
        callback();
      }
    };
    return {
      uploadHeader: {
        "Session-Id": localStorage.token,
      },
      invalidVisible: false,
      dialogVisible: false,
      plateNumber: "",
      rowData: {},
      temParkData: {},
      temParkData1: {},
      infoSize: 4,
      exportData: {},
      index: 0,
      total: 0,
      pageSize: 15,
      pageNum: 1,
      loading: false,
      modelvalue1: "",
      rule: {
        mobile: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
          {
            validator: checkPhone,
            trigger: "blur",
          },
        ],
        text: [
          {
            required: true,
            message: "请选择",
            trigger: "blur",
          },
        ],
      },
      option: [
        {
          label: "选项一",
          value: 1,
        },
        {
          label: "选项二",
          value: 2,
        },
      ],
      tableCols: [
        {
          prop: "index",
          label: "序号",
          width: "",
        },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          formatter: (row) => {
            return row.plateNumber && row.plateNumber.indexOf("无") != -1
              ? "无牌车"
              : row.plateNumber;
          },
          width: "",
        },
        {
          prop: "mobile",
          label: this.$t("list.phone_number"),
          width: "",
        },
        {
          prop: "channelDesc",
          label: "渠道",
          width: "",
        },
        {
          prop: "remarkDesc",
          label: "备注",
          width: "",
        },
        {
          prop: "updatedTime",
          label: this.$t("list.peration_date"),
        },
        {
          prop: "operatorName",
          label: this.$t("list.Operator"),
          width: "",
        },
      ],
      tableData: [],
      page: 1,
      size: 15,
      pickerOptions: {
        disabledDate: (val) => {
          // const now = new Date().getTime();
          return val.getTime() > now;
        },
      },
      rangeDate: [dateFormat(start), dateFormat(now)],
      formInline: {
        carId: "",
        mobile: "",
        channel: "",
        remark: "",
        operatorName: "",
        startCreatedTime: dateFormat(start),
        endCreatedTime: dateFormat(now),
      },
      addformInline: {},
      fileList: [],
      detailformInline: {},
      remarkList: [], // 无效电话
      phoneTypeList: [], // 渠道
      audioUrl: "",
    };
  },
  methods: {
    dealBlack(black) {
      if (black == 1) {
        // 1:投诉  2：多次拨打无人接听 3：空号  4：其他
        return "投诉";
      } else if (black == 2) {
        return "多次拨打无人接听";
      } else if (black == 3) {
        return "空号";
      } else if (black == 4) {
        return "其他";
      }
    },
    changeTime(rangeDate) {
      if (rangeDate) {
        this.formInline.startCreatedTime = rangeDate[0];
        this.formInline.endCreatedTime = rangeDate[1];
      } else {
        this.formInline.startCreatedTime = "";
        this.formInline.endCreatedTime = "";
      }
    },
    // 时间判断
    showLog() {
      if (this.rangeDate) {
        if (this.rangeDate[0] && this.rangeDate[1]) {
          let time = new Date(this.rangeDate[1]) - new Date(this.rangeDate[0]);
          time = time / (24 * 60 * 60 * 1000);
          if (time > 366 && this.formInline.carId == "") {
            this.$alert("查询时间不能超过366天");
            return false;
          } else {
            return true;
          }
        }
      } else {
        return true;
      }
    },
    handleCommand(cmd, data) {
      if (cmd == "a") {
        this.dialogVisible = true;
        this.getDetailPhone(data);
      }
      if (cmd == "b") {
        this.$confirm("此操作将移除本条无效电话, 是否继续?", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let url = "/acb/2.0/carMobile/delInvalidMobile/";
            this.$axios
              .post(url, {
                data: {
                  invalidMobileId: data.invalidMobileId,
                },
              })
              .then((res) => {
                if (res.state == 0) {
                  this.$alert("删除成功！", this.$t('pop_up.Tips'), {
                    confirmButtonText: this.$t('pop_up.Determine'),
                  });
                  this.searchData();
                } else {
                  this.$alert(res.desc, this.$t('pop_up.Tips'), {
                    confirmButtonText: this.$t('pop_up.Determine'),
                  });
                }
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      }
    },
    add() {
      this.invalidVisible = true;
    },
    // 查看详情
    getDetailPhone(data) {
      this.$axios
        .get("/acb/2.0/carMobile/getInvalidMobile", {
          data: {
            invalidMobileId: data.invalidMobileId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.detailformInline = res.value;
            this.audioUrl = res.value.url ? res.value.url : "";
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 无效电话备注枚举
    getremarkList(data) {
      this.$axios.get("/acb/2.0/carMobile/remark/list").then((res) => {
        if (res.state == 0) {
          this.remarkList = res.value;
        }
      });
    },
    // 渠道枚举
    getphoneTypeList(data) {
      this.$axios.get("/acb/2.0/carMobile/phoneType/list").then((res) => {
        if (res.state == 0) {
          this.phoneTypeList = res.value;
        }
      });
    },
    closeDialogFn() {
      this.clearAddForm();
      this.invalidVisible = false;
      if (this.$refs.audio.play()) {
        this.$refs.audio.pause();
        this.$refs.audio.currentTime = 0;
      }
    },
    audioChange(file) {
      this.fileList = [];
      this.fileList.push(file);
    },
    uploadFile(file) {
      console.log("file0----", file);
      if (!file) {
        this.blackListVisible = false;
        return;
      }
      let formData = new FormData();
      formData.append("image", file.file);
      formData.append("carMobileBlackId", this.carMobileBlackId);
      const opt = {
        url: "/acb/2.0/carMobileBlack/uploadAudio",
        method: "post",
        data: formData,
        config: {
          headers: {
            "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundarynl6gT1BKdPWIejNq",
          },
        },
        success: (res) => {
          if (res.state == 0) {
            this.blackListVisible = false;
          } else {
            this.$message({
              type: "error",
              message: "上传失败!",
            });
          }
        },
        fail: () => {},
      };
      this.$request(opt);
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searParkRecordList();
    },
    /* 车牌号 */
    querySearchAsync(queryString, cb) {
      this.loading = false;
      if (queryString.length < 3) {
        cb([]);
        this.formInline.carId = "";
        return;
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/plateNumber/list",
        data: {
          part: queryString,
          size: 100,
        },
      };
      this.$axios(opt).then((res) => {
        if (res.state == 0) {
          res.value.forEach((v) => {
            v.value = v.plateNumber;
          });
          cb(res.value);
        }
      });
    },
    handleSelect(item) {
      this.formInline.plateNumber = item.plateNumber;
      this.formInline.carId = item.carId;
    },
    searchData() {
      let flag = this.showLog();
      if (flag) {
        this.$axios
          .get("/acb/2.0/carMobile/queryInvalidMobile", {
            data: {
              pageNum: this.pageNum,
              pageSize: this.pageSize,
              ...this.formInline,
            },
          })
          .then((res) => {
            this.loading = false;
            if (res.state == 0) {
              this.tableData = setIndex(this.pageNum, res.value.list);
              this.total = res.value.total * 1 || 0;
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          });
      }
    },
    // 搜索
    searParkRecordList() {
      // console.log(this.formInline);
      this.loading = true;
      this.searchData();
    },
    saveAddBlackFn() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
        }
      });
    },
    exportFile() {
      let data = {
        page: this.pageNum,
        pageSize: this.pageSize,
        carId: this.formInline.carId || "",
        mobile: this.formInline.mobile || "",
        parkId: this.formInline.parkId || "",
        payType: this.formInline.payType || "",
        startCreatedTime: this.rangeDate[0],
        endCreatedTime: this.rangeDate[1],
      };
      exportExcelNew("/acb/2.0/staggeredOrder/export", data, "post");
    },
    resetForm() {
      this.formInline.carId = "";
      this.formInline.mobile = "";
      this.formInline.operatorName = "";
      this.formInline.channel = "";
      this.formInline.plateNumber = "";
      this.formInline.remark = "";
      this.plateNumber = "";
      this.rangeDate = [dateFormat(start), dateFormat(now)];
    },
  },
  components: {},
  created() {
    if (this.$route.params.tradeNo) {
      this.formInline.tradeNo = this.$route.params.tradeNo;
      this.rangeDate = [];
    }
  },
  mounted() {
    // console.log("当前权限为", this.$route.meta.authority.button);
    this.pageNum = 1;
    this.pageSize = 15;
    this.searchData();
    this.getremarkList();
    this.getphoneTypeList();
  },
  watch: {
    plateNumber(newVal) {
      if (!newVal) {
        this.formInline.plateNumber = "";
        this.formInline.carId = "";
      }
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.color_1 {
  color: #3d94dd;
}

.color_2 {
  color: #D9001B;
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.avatar-uploader-icon {
  cursor: pointer;
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
}

.avatar {
  width: 100%;
  display: block;
}
</style>
